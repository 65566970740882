/* Ensure input field background is white */
.input-group .typeahead-custom .rbt-input {
  background-color: white !important; 
}

/* Ensure dropdown menu background is white */
.input-group .typeahead-custom .rbt-menu {
  background-color: white !important;
  color: black !important; 
  display: flex;
  flex-direction: column;
  z-index: 999;
  
}

/* Ensure each dropdown item has a white background and is displayed in a column */
.input-group .typeahead-custom .rbt-menu .dropdown-item {
  background-color: white !important;
  color: black !important;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 10px;
  
}
@media (max-width: 360px) {
  .input-group .typeahead-custom .rbt-menu .dropdown-item {
    font-size: 13px; /* Adjusted size for smaller screens */
  }
}
/* Light grey background on hover and focus for each dropdown item */
.input-group .typeahead-custom .rbt-menu .dropdown-item:hover,
.input-group .typeahead-custom .rbt-menu .dropdown-item:focus {
  background-color: #f0f0f0 !important;
}