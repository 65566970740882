*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
.side-item {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd; /* Light border */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for visual changes */
}

/* Hover effect for non-selected items */
.side-item:hover {
  background-color: var(--color-e7cc67); /* Light gray background on hover */
}

/* Style for selected item */
.side-item.selected {
  background-color: var(--color-15131f)!important; /* Background color when selected */
  color: white!important;  /* Text color becomes white for contrast */
  border: 1px solid #333;  /* Darker border for selected item */
}

/* Prevent hover effect when the item is selected */
.side-item.selected:hover {
  background-color: black;  /* Keep the black background for selected items */
  color: white;  /* Ensure text stays white */
}
.success-message {
  margin-bottom: 5px;
}
.error-message {
  color: red;
  font-size: 13px;
}
.text-centerrr {
  text-align: center;
  color: var(--color-e7cc67);
  font-family: "Rubik", sans-serif;
  font-size: 13px;
}
.react-multiple-carousel__arrow {
  z-index: 1!important;
}
.price-styleet {
  margin-top: 2px;
  color: var(--color-972e22);
  margin-bottom: 5px;
  
}
.price-styleet strong {
  font-size: 12px;
  color: var(--color-972e22);
}
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.skeleton-item {
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeleton-text {
  height: 20px;
  width: 80%;
  margin-bottom: 10px;
}

.skeleton-image {
  height: 200px;
  width: 100%;
}
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

/* SpinnerLoader.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}


.spinner {
  width: 50px; /* Customize the size */
  height: 50px;
}

/* Container for the drop-up */
.dropup-container {
  position: relative;
  display: inline-block;
  
  
}

/* Drop-up menu styles */
.dropup-menu {
  position: absolute;
  bottom: 118%; /* This makes the menu appear above the NavLink */
  left: 0;
  right: 20px; /* Set a margin or adjust as needed */
  background-color: var(--color-f1eae4);
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Expand to full width */
  min-width: 400px; /* Minimum width for larger viewports */
  z-index: 100;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Hide the element from the layout */
  transform: translateY(10px); /* Slight vertical shift for transition effect */
  transition: all 0.3s ease-in-out; /* Smooth transition for transform and opacity */
}
.dropup-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
}


/* Show the drop-up menu on hover or when active */
.dropup-container:hover .dropup-menu,
.dropup-container.active .dropup-menu {
  opacity: 1; /* Make it visible */
  visibility: visible; /* Ensure it's part of the layout */
  transform: translateY(0); /* Reset vertical shift to its original position */
}

/* Drop-up menu list styles */
.dropup-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropup-menu li {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.dropup-menu li:last-child {
  border-bottom: none;
}

.dropup-menu li a {
  text-decoration: none;
  color: black;
  display: block;
}

.dropup-menu li:hover {
  background-color: #f0f0f0;
}

/* Additional styles for NavLink */
.navbar-mobi {
  color: black;
  text-decoration: none;
}

.navbar-mobi.active {
  color: blue; /* Change color when active */
}

/* Icon styles */
.fs-nav3 {
  font-size: 24px;
}
/*
.rajdhani-light {
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.rajdhani-regular {
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.rajdhani-medium {
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.rajdhani-semibold {
  font-family: "Rajdhani", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.rajdhani-bold {
  font-family: "Rajdhani", sans-serif;
  font-weight: 700;
  font-style: normal;
}
*/
p {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.product-details .font-star {
  font-size: 24px; /* Default size for larger screens */
}
.addwish-ic {
  cursor: pointer;
}
.col-none {
  display: none;
}

.mt-8 {
  margin-top: 8px;
}
.t-policy {
  padding: 20px;
}

.error {
  color: red;
  font-size: 12px;
}
.mt-20 {
  margin-top: 20px;
}

.w-25 {
  width: 25%;
}
.cat-img {
  width: 100px;
}
.w-75 {
  width: 75%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.container-xxl {
  width: 100%;
}
.p-30 {
  padding: 30px;
}
.para-13 {
  font-size: 13px;
}
.border-0 {
  border: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-10 {
  margin-top: 10px;
}
.p-3 {
  padding: 15px;
}
.bg-white {
  background-color: white;
}
:root {
  --color-282828: #282828;
  --color-dddddd: #b2b1b1;
  --color-e7cc67: #e7cc67;
  --color-f5f5f7: #f5f5f7;
  --color-494949: #494949;


  --color-15131f: #15131f;
  --color-972e22: #972e22;
  --color-dc4814: #dc4814;
  --color-ee702a: #ee702a;
  --color-f1eae4: #f1eae4;
  --color-fbf7f7: #fbf7f7;

}
.check-none {
  display: none;
}
.section-11 {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.sec-11 {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.flex-column {
  flex-direction: column;
}

.gap-15 {
  gap: 15px;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.w-100 {
  width: 100%;
}
.mb-0 {
  margin-bottom: 0;
}
.d-flex {
  display: flex;
}
.gap-10 {
  gap: 10px;
}
.cont-wrap > div {
  width: 48%;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
body {
  font-family: "Rubik", sans-serif;
  
}
.d-block {
  display: block;
}
.d-none {
  display: none;
}
.py-3 {
  padding-left: 120px;
  padding-right: 120px;
}
a {
  text-decoration: none;
  display: inline-block;
}

/*Header Part*/
.mobile-header {
  display: none;
}
.header-top-strip {
  background-color: #15131f;
  font-weight: 200;
  border-bottom: 1px solid var(--color-494949);
  padding-left: 130px;
  padding-right: 130px;
  width: 100%;
  z-index: 101;
  
}
.header-top-strip .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-white {
  color: var(--color-dddddd);
  font-size: 14px;
  
}

.col-7 {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.col-7 .link-strip {
  padding-right: 20px;
  padding-left: 20px;
  border-right: 1px solid #535353;
}
.col-7 .link-strip p {
  font-weight: 100;
  font-size: 14px;
  color: var(--color-dddddd);
  transition: 0.3s ease;
}
.col-7 .link-strip:hover p {
  color: var(--color-e7cc67);
}

.header-upper {
  background-color: #15131f;
  border-bottom: 1px solid black;
  position: sticky;
  top: 0; /* Stick to the top after the top header disappears */
  z-index: 100;
  transition: top 0.3s; /* Smooth transition when hiding */
}

.header-upper .container-xxl .row {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.logo-1 {
  display: flex;
  align-items: center;
  margin-right: 40px;
  border-right: 0.01px solid #535353 ;
  padding-right: 20px;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}
.input-group {
  width: 60%;
  width: 600px;
  background-color: white;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  
}
.form-control {
  color: #282828;
  outline: none;
  border: none;
  background-color: transparent;
  flex: 1;
  font-size: 14px;
  height: 35px;
  padding-left: 10px;
}
.form-control::placeholder{
  color: #282828;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
}
#basic-addon2{
  display: flex;
  align-items: center;
  background-color: var(--color-e7cc67);
  font-size: 14px;
  height: 36px;
  padding: 5px 15px;
  border: none;
  outline: none;
  cursor: pointer;
}
#addon2{
  display: flex;
  align-items: center;
  background-color: var(--color-e7cc67);
  font-size: 14px;
  height: 36px;
  padding: 5px 15px;
  border: none;
  outline: none;
  cursor: pointer;
}
.header-upper-links {
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.display-link {
  display: flex;
  align-items: center;
  margin-left: 40px;
  padding: 2px 4px;
  border: 0.01px solid #2d2d2d;
  box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
  box-shadow:  1px 1px 1px black;
}

.display-link:hover {
  box-shadow:  2px 2px 2px black;
}
.display-link img {
  margin-right: 5px;
}
.display-link p,
.badge {
  font-size: 13px;
  color: var(--color-dddddd);
}
.display-link:hover p {
  color: var(--color-e7cc67);
}

.header-bottom {
  border-bottom: 1px solid #eaeaea;
  padding-top: 5px;
  padding-bottom: 5px;
  
 
}

.menu-bottom {
  display: flex;
  align-items: center;
}
.link {
  display: flex;
  align-items: center;
  
  
}
.header-bottom .menu-links a{
  line-height: 18px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 100;
  color: var(--color-15131f);
  font-weight: 500;
  padding: 10px;
  letter-spacing: 0.3;
  transition: 0.3s ease;
}
.header-bottom .menu-links a:hover {
  color: var(--color-e7cc67);
}

.menu-trigger {
  border: 1px solid #eaeaea;
  padding: 5px 12px;
  cursor: pointer;
  background-color: #15131f;
  margin-right: 30px;
}
.btn-drop {
  
  display: flex;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 100;
  outline: none;
  font-weight: 300;
  color: var(--color-dddddd);
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 999;
}
.btn-drop img {
  margin-right: 5px;
}
.menu-trigger:hover .btn-drop {
  color: var(--color-e7cc67);
  
}

.dropdown-menu.active {
  background-color: var(--color-f1eae4);
  opacity: 1;

  visibility: visible;
  transform: translateY(0);
  transition: 0.2s ease;
  z-index: 999;
  text-align: left;
}
.dropdownItem {
  list-style: none;
  margin: 15px;
  cursor: pointer;
  padding-left: 10px;
  padding-bottom: 3px;
  transition: 0.3s ease;
  color: var(--color-15131f);
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
}
.dropdownItem:hover {
  color: var(--color-e7cc67);
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 0.2s ease;
  z-index: 999;
}

.category-procedure li {
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-bottom: 1px solid #1f1f1f;
  cursor: pointer;
  transition: 0.3s ease;
}
.category-procedure li a{
  background-color: transparent;
}

.category-procedure li:hover {
  box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.category-procedure li:hover .categories{
  color: var(--color-e7cc67);
}
.categories {
  transition: 0.3s ease;
  font-weight: 100;
  font-size: 14px;
  color: var(--color-dddddd);
}

.dropdown-menu {
  position: absolute;
  background-color: #313131;
  width: 175px;
}
.logout-b {
  cursor: pointer;
  margin-left: 10px;
  background: transparent;
  border: none;
  font-size: 13px;
  font-weight: 600;
  transition: 0.3s ease;
}
.logout-b:hover {
  color: var(--color-e7cc67);
}
.dis-cat {
  display: none;
}

/*Header Part End Here*/



/*Footer Part*/
footer {
  background-color: var(--color-15131f);
  padding-left: 130px;
  padding-right: 130px;
  
}
footer:not(:first-child) {
  border-top: 1px solid rgb(65, 65, 65);
}

.form-foot{
  color: #282828;
  outline: none;
  border: none;
  background-color: transparent;
  flex: 1;
  font-size: 14px;
  height: 35px;
  padding-left: 10px;
}
footer .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-top {
  display: flex;
  align-items: center;
}

.footer-top img {
  margin-right: 30px;
}
.footer-top .sign-text {
  font-weight: 400;
  font-size: 20px;
  color: var(--color-dddddd);
  font-family: "Rubik", sans-serif;
}



.text-center {
  text-align: center;
  color: var(--color-dddddd);
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  
}
.col-7 .input-group .form-control {
  font-family: "Rubik", sans-serif;
  border: 1px solid var(--color-e7cc67);
  background-color: var(--color-282828);
}
.col-7 .input-group .form-control::placeholder {
  font-family: "Rubik", sans-serif;
}
.footer-info {
  padding-top: 40px;
  padding-bottom: 40px;
}
.info-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.text-info-footer {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dddddd);
  padding-bottom: 15px;
}
.footer-links {
  display: flex;
  flex-direction: column;
}
.footer-link-text {
  padding-top: 10px;
  font-weight: 100;
  font-size: 14px;
  color: var(--color-dddddd);
}
.para-install {
  font-size: 14px;
  color: var(--color-dddddd);
}
.footer-link-text:hover {
  text-decoration: underline;
}
.install-icons {
  padding-top: 20px;
}
.icon-install {
  border-radius: 5px;
  border: 1px solid #535353;
  margin-right: 8px;
  box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease;
}
.icon-install:hover {
  border: 1px solid var(--color-e7cc67);
}
.address {
  color: var(--color-dddddd);
  font-size: 14px;
  font-style: normal;
  padding-top: 10px;
  font-weight: 100;
}
.footer-contact {
  display: flex;
  flex-direction: column;
}
.mobile,
.email {
  color: var(--color-dddddd);
  padding-top: 15px;
  font-size: 14px;
  font-weight: 200; 
}
.social-icons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icons a{
  text-align: center;
}
.s-icon {
  justify-content: center;
  padding: 9px 12px;
  margin-right: 5px;
  background-color: #08070b;
  border-radius: 50px;
  border: 0.01px solid #383838;
  transition: 0.3s ease;
}
.s-icon:hover {
  border: 0.01px solid black;
}
.container-end {
  display: flex;
  justify-content: center;
}

/*Footer Part End Here*/

.gap-9 {
  gap: 8.5px;
}

/*Home Part*/
.print-sty {
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 15px 40px;
}
.print-sty h2 {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-494949);
}
.print-sty p {
  font-size: 14px;
  font-weight: 200;
}
.custom-blog-carousel .slick-dots {
  margin-top: 30px !important;  /* Adjust this value to your desired space */
}
.custom-blog-carousel {
  width: 100%;
}
.mb-21 {
  margin-bottom: 15px;
  min-width: 320px !important; /* Override width to ensure it's at least 320px */
  width: auto !important;  /* Allow width to adjust dynamically */
}
.search-unit {
  display: none;
}
.ban {
  display: none;
}

.hero {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: 84vh;
  object-fit: cover;
}


.dis-no {
  display: none;
}
.dis-post {
  display: none;
}
.not-large {
  display: none;
}
.mm-r {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.print-mob {
  display: none;
  background-color: var(--color-e7cc67);
  border-top: 1px solid var(--color-dddddd);
  border-bottom: 1px solid var(--color-dddddd);
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  color: var(--color-15131f);
  font-size: 13px;
}
.home-banner-section {
  margin-top: 10px;
  margin-bottom: 5px;
}
.main-banner {
 
  position: relative;
  
}
.other-banners {
  width: 49%;
 
}
.other-bann {
  display: none;
}
.border-0 {
  border: 0;
}
.bg-transparent {
  background: transparent;
}


.main-banner-content {
  top: 20%;
  left: 8%;
  position: absolute;
}
.banner-section-1 {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  
}
.main-banner {
  width: 49.5%;
  min-width: 620px;
}
.main-banner-in {
  position: relative;
  
}


.main-banner-content .print {
  margin-top: 20px;
  color: var(--color-e7cc67);
  font-size: 14px;
  padding: 15px 20px;
  background-color: transparent;
  border: 1px solid var(--color-e7cc67);
  border-radius: 5px;
  transition: 0.3s ease;
  box-shadow: inset 2px 3px 2px 2px rgba(0, 0, 0, 0.2);

}
.file-size {
  font-size: 13px;
}
.main-banner-content .print:hover {
  color: var(--color-f1eae4);
  background-color: transparent;
  border: 1px solid #7c7c7c;
  box-shadow: inset 2px 3px 2px 2px rgba(0, 0, 0, 0.2);

}
.main-banner-content h4 {
  font-size: 24px;
  color: var(--color-e7cc67);
  margin-bottom: 10px;
}
.main-banner-content h5 {
  color: var(--color-fbf7f7);
  font-size: 80px;
  font-weight: 800;
}
.main-banner-content h6 {
  color: var(--color-fbf7f7);
  font-size: 60px;
  margin-bottom: 10px;
  font-weight: 100;
}
.main-banner-content h6 span {
  color: var(--color-15131f);
  background-color: var(--color-e7cc67);
  padding: 2px 12px;
  border-radius: 50%;
  font-size: 40px;
  margin-bottom: 10px;
  font-weight: 300;
}
.main-banner-content p {
  color: var(--color-fbf7f7);
  font-size: 24px;
  font-weight: 100;
  line-height: 32px;
}
.main-banner-content .clin {
  background-color: var(--color-e7cc67);
  padding: 1px 8px;
  color: var(--color-15131f);
  border-radius: 20px;
}

.small-banner {
  width: 310px;
  position: relative;
  margin-bottom: 2px;
  margin-left: 8px;
  
}
.small-banner:hover .banner-small-1 {
  transform: scale(1.01);
  border: 1px solid #d8d8d8;
}
.home-icon {
  color: var(--color-15131f);
  margin-right: 10px;
}
.services .serv h6 {
  color: var(--color-15131f);
}
.services .serv p {
  color: var(--color-15131f);
}
.banner-small-1 {
  transition: all 0.3s ease;
  width: 100%;
  border-radius: 10px;
}
.small-banner-content {
  position: absolute;
  top: 40px;
  left: 20px;
}
.small-banner-content h5 {
  color: var(--color-282828);
  font-size: 22px;
  margin-bottom: 10px;
}
.small-banner-content p {
  color: var(--color-282828);
  font-size: 14px;
  
}



/*home section2*/
.banner-section-2 {
  background-color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 130px;
  padding-right: 130px;
  
  
}
.services {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.serv{
  display: flex;
}
.serv img{
  margin-right: 10px;
}
.serv h6 {
  color: var(--color-dddddd);
  font-weight: 300;
  font-size: 14px;
}
.serv p {
  color: var(--color-dddddd);
  font-size: 13px;
}
.flex-1 {
  flex: 1;
}


/*home section2 end here*/



/*categories section*/
.banner-section-3 {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.categories {
  display: flex;  
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
}

.cat {
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 24%;
 
}
.cat h6 {
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  margin-bottom: 8px;
}
.cat p {
  font-size: 14px;
  color: #494949;
}
.mr-0 {
  margin-right: 0;
  
}

.cat:nth-child(1),
.cat:nth-child(2),
.cat:nth-child(3),
.cat:nth-child(4) {
  border-bottom: 1px solid var(--color-dddddd);
}

.cat:nth-child(1),
.cat:nth-child(2),
.cat:nth-child(3) {
  border-right: 1px solid var(--color-dddddd);
}

/*categories section end here*/
.sub-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sub-banner {
  width: 33%;
  min-width: 300px;
  position: relative;
}
.sub-details {
  text-align: end;
  color: var(--color-fbf7f7);
  position: absolute;
  top: 10px;
  right: 0px;
}
.sub-details p {
  padding-right: 10px;
  text-align: start;
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.sub-details h1 {
  font-size: 30px;
  text-align: start;
  
}
.bord-b {
  margin-top: 2px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-fbf7f7);
  width: 40px;
}
.blue-f {
  color: var(--color-15131f);
}
.quali-s {
  padding-left: 10px;
  text-align: start;
  font-size: 30px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: var(--color-f1eae4);
  color: var(--color-15131f);
}
.new-qua {
  background-color: var(--color-15131f);
  color: var(--color-fbf7f7);
}
.b-new {
  border-bottom: 1px solid var(--color-15131f);
}
.w-bl {
  color: var(--color-15131f)!important;
  font-weight: 200!important;
}

/*Home Part End Here*/

/*Blog Section*/
.blog-wrapper {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.blog {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.blog-carousel {
  display: flex;
  justify-content: space-between;  /* Center items to ensure active item is centered */
  flex-wrap: wrap;  /* Prevent wrapping so the carousel slides horizontally */
  gap: 10px;
  width: 100%;
  overflow: hidden;  /* Hide items that are off-screen */
  position: relative;
}

/* Ensure .col-4 has consistent widths for different breakpoints */
.blog-carousel .col-4 {
  position: relative;
  color: var(--color-15131f);
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  width: 15%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
}
.react-multi-carousel-item {
  width: 250px;  /* Change this to the desired width */
  flex: 0 1 auto;  /* Ensures items do not stretch and follow the width */
  margin: 10px;  /* Optional: Adds spacing between items */
}


.section-heading {
  padding-bottom: 5px;
  border-bottom: 1px solid #d5d5d5;
  color: var(--color-15131f);
  font-size: 24px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}
.product-details {
  text-align: center;
}
.blog-card {
  background-color: white;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px #0000001a;
}
.image-c {
 
  width: 100%;
  transition: 0.3s ease;
}
.image-c:hover {
  transform: scale(1.01);
}
.blog-content {
 
  padding: 15px;
}
.button {
  color: var(--color-dddddd);
  font-size: 14px;
  padding: 15px 20px;
  background-color: var(--color-15131f);
  border-radius: 5px;
  transition: 0.3s ease;
}
.button:hover {
  color: var(--color-15131f);
  background-color: transparent;
  border: 1px solid var(--color-15131f);
}

.blog-content .date {
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
}
.blog-content .title {
  font-size: 16px;
  margin-bottom: 8px;
}
.blog-content .description {
  color: #808080;
  font-size: 14px;
  margin-bottom: 10px;
}





/*Blog Section end here*/
.quantity-btn {
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.quantity-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.quantity-display {
  padding: 0 10px;
  font-size: 16px;
}

/*Product cart*/

.carousel-item-padding-40-px {
  padding: 0 20px; /* Adjust padding as needed */
}

.item-carousel {
  flex: 1 0 21%; /* Adjust to ensure items fit correctly */
  display: flex;
  justify-content: center;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}
.react-multiple-carousel__arrow {
  background: rgba(28, 28, 77, 0.5);
  min-width: 38px;
  min-height: 38px;
}
.react-multi-carousel-dot--active button {
  background: var(--color-15131f);
}
.react-multi-carousel-dot button {
  
  border-color: var(--color-15131f);
  
}
.wish-c:hover {
  color: var(--color-972e22);
}
.wish-c {
  color: var(--color-15131f);
}
.col-4 {
  position: relative;
  color: var(--color-15131f);
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  width: 15%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
}

.col-4:hover {
  border: 0.01px solid #c4c4c4;
}

.wish {
  position: absolute;
  right: 0px;
  top: 0px;
}
.prod-img {
  width: 100%;
  transition: 0.3s ease;
}
.product-card .descript {
  color: var(--color-dddddd);
  font-size: 13px;
  font-weight: 100;
  margin-top: 5px;
}
.prod-img:hover {
  transform: scale(1.1);
}
.product-details strong {
  font-size: 14px;
 
}
.product-details, .brand {
  font-size: 13px;
  color: var(--color-282828);
}
.price-style {
  margin-top: 8px;
  color: var(--color-282828);
}
.wish {
  position: absolute;
  top: 10px;
  right: 10px;
}
.fs-3 {
  font-size: 18px;
  color: #313131;
}
.add-c {
 border: none;
}

.add-to-cart {
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 10px;
}



/*Product cart end here*/


/*special products*/
.special-wrapper {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px!important;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.prod-s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.special-product-card {
  background-color: white;
  width: 95%;
  min-width: 250px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001a;
  
}
.s-p {
  
  display: flex;
 
  padding: 10px;
}
.s-p-img {
  width: 180px;
}
.pro-small-img {
  display: flex;
  gap: 3px;
  margin-top: 10px;
}
.s-p-s-img {
  width: 40px;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  padding: 2px;
}
.view-p {
  border-radius: 5px;
  margin-top: 15px;
  color: #c5c5c5;
  background-color: var(--color-282828);
  padding: 10px 20px;
}
.dis-flex {
  display: flex;
  gap: 10;
  align-items: center;
}
.badge-2 {
  color: white;
  border: 1px solid rgb(219, 219, 219);
  background-color: #d3141a !important;
  padding: 5px 12px;
  margin: 4px;
  border-radius: 50%;
}
.special-product-content h5 {
  margin-top: 5px;
  font-size: 14px;
}
.des-special {
  font-size: 14px;
}
.discount-till {
  display: flex;
  align-items: center;
}
.progress {
  background-color: #e8e8e8;
  border-radius: 8px;
}
.progress-bar {
  background-color: rgb(255, 179, 0);
  padding: 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.prod-count {
  margin-top: 10px;
}
.prod-count p {
  margin-bottom: 8px;
}
.button-add {
  color: var(--color-dddddd);
  font-size: 14px;
  padding: 10px 15px;
  background-color: var(--color-15131f);
  border-radius: 20px;
  transition: 0.3s ease;
  margin-top: 20px;
  margin-bottom: 10px;
}

.button-add:hover {
  color: var(--color-15131f);
  background-color: transparent;
  border: 1px solid var(--color-282828);
}

/*special products end here*/



/*Popular Products*/
.col-9 {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  width: 15%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  
}
.col-9 h2 {
  font-size: 18px;
}
.col-10 h2 {
  font-size: 18px;
}
.col-9 h2 span, .col-10 h2 span {
  font-weight: 100;
}
.post-p {
  font-size: 13px;
}
.col-10 {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  width: 15%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
}
.our-pop {
  display: none;
}




/*Popular Products end here*/


/*Description section*/
.descrip-section {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
  
}

.des-cont {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.desss {
  width: 22%;
  min-width: 300px;
  position: relative;
}
.color-w {
  color: white;
}
.descrip-photo {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001a;
  border: 1px solid #b2b1b1;
}
.black-back {
  
  color: var(--color-dddddd);
}
.des-doc h6 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 200;
}
.position-absolute {
  position: absolute;
  left: 20px;
  top: 20px;
}


.des-doc h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}
.des-doc p {
  font-size: 14px;
  margin-bottom: -10px;
}


/*our store page*/
.pagin-des {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.pagination {
  display: flex;
}
.pagin-icon {
  font-size: 18px;
  margin-bottom: 0;
  cursor: pointer;
}
.col-20 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.center-t {
  text-align: center;
}
.form-contr {
  outline: none;
  border: 1px solid var(--color-dddddd);
  flex: 1;
  padding: 8px;

}


.store-wrapper {

  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 118px;
  padding-right: 118px;
  display: flex;
  gap: 10px;
  
  
}
.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 25px;
  margin-bottom: 10px;
  max-width: 245px;
  box-shadow: 0 0 10px #0000001a;
}
.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 20px;

}
.filter-card ul,
.filter-card .form-check-label {
  list-style: none;
  line-height: 30px;
  font-size: 13px;
  color: var(--color-494949);
  cursor: pointer;
}
.sub-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 20px;
  
}
.form-check {
  display: flex;
  align-items: center;
}
.filter-card .form-check-label {
  margin-left: 4px;
  
}

.form-control-2 {
  background-color: var(--color-f5f5f7);
  color: #282828;
  width: 100%;
  border: none;
  outline: none;
  padding: 5px;
}
.form-control-2::placeholder {
  color: var(--color-494949);
}
.price-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.colors li {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  
}
.product-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.badge-span {
  color: #535353;
  font-size: 13px;
  background-color: var(--color-f5f5f7);
  padding: 5px;
  border: 1px solid rgb(203, 203, 203);
}
.random-prod {
  display: flex;
  gap: 5px;
  padding-bottom: 15px;
}

.random-prod:first-child {
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 10px;
}
.w-25 {
  width: 40%;
  min-width: 110px;
}
.w-75 {
  
  
  flex: 1;
}
.rando-img {
  width: 100%;
}

.w-75 strong,
.w-75 p {
  font-size: 14px;
}

.col-22 {
  flex: 1;
}
.sort-grid-dis {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-display {
  width: 55%;
  display: flex;
  align-items: center;
  gap: 10px;
  
}
.filter-sort-grid {
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  
}
.right-dis {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bar-icon {
  width: 28px;
  height: 28px;
  background-color: var(--color-f5f5f7);
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}
.dark {
  background-color: var(--color-282828);
}
.filter-display p {
  width: 80px;
  font-size: 14px;
}
.form-cont {
  padding: 10px;
  outline: none;
  border: none;
  background-color: var(--color-f5f5f7);
}


.totalproducts {
  font-size: 13px;
  color: var(--color-494949);
}
.products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gr-3 {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  width: 12%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
}
.gr-4 {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  width: 23%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
}
.gr-6 {
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  width: 26%;
  min-width: 200px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;

}

.gr-12 {
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    min-width: 200px;
    box-shadow: 0 0 10px #0000001a;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
}
.gr-12 .product-card {
  display: flex;
  gap: 20px;
  align-items: center;
  
}
.gr-12 .product-card .prod-img {
  width: 200px;
}
.gr-3:hover {
  border: 0.01px solid #c4c4c4;
}
.gr-4:hover {
  border: 0.01px solid #c4c4c4;
}
.gr-6:hover {
  border: 0.01px solid #c4c4c4;
}
.gr-12:hover {
  border: 0.01px solid #c4c4c4;
}

/*our store page ends*/
/*profile*/
.wel-back {
  background-color: #15131f;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--color-dddddd);
}
.wel-back h2 {
  margin-bottom: 20px;
}
.curso {
  cursor: pointer;
}
.logoutpro-b {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: #dfdfdf;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
}
.logoutpro-b:hover {
  background-color: var(--color-e7cc67);
  
}
.form-lab {
  padding-left: 10px;
}
/*profile*/


/*contact page*/
.col-25 {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.cont-wrap {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 30px 20px;
  border-radius: 15px;
  gap: 15px;
  
 
}

.cont-wrap > div {
  width: 48%;
}
.contact-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
.form-c {
  background-color: var(--color-f5f5f7);
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
}
.form-c::placeholder {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
}
.formms {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.text-b {
  padding: 10px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #535353;
  
}
.text-b::placeholder {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  
}
.button-cont {
  font-size: 14px;
  color: var(--color-f5f5f7);
  background-color: var(--color-15131f);
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}
.button-cont:hover {
  background-color: transparent;
  border: 1px solid var(--color-494949);
  color: var(--color-282828);
}
.cont-wrap ul {
  list-style: none;
}
.list-cont {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.addre {
  font-style: normal;
  font-size: 14px;
  color: #282828;
}

/*contact page ends here*/
.l-none {
  display: none;
}
.ab-none {
  display: none!important;
}


/*whishlist page*/
.wishlist-wrapper {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: calc(100vh - 40px);
  box-sizing: border-box; 
}
.wishlist-description strong {
  font-size: 14px;
}

.wishlist-description p {
  font-size: 14px;
}
.wishlist-description  {
  text-align: center;
}
.col-26 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.wishlist-card {
  width: 13%;
  min-width: 100px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 5px;
}
.whishlist-card:hover {
  border: 0.01px solid #c4c4c4;
}
.wishlist-card .cross {
  top: 3px;
  left: 140px;
  padding: 3px;
  cursor: pointer;
}
.whishlist-card-image {
  background-color: white;
}
.gap-5 {
  gap: 5px;
}

.whish-des {
  padding: 15px;
}

/*whishlist page ends here*/



/*login*/
.password-container {
  position: relative;
}

.password-container input {
  padding-right: 30px; /* Add some space on the right side for the icon */
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right position */
  transform: translateY(-50%);
  cursor: pointer;
}

.login-wrapper {
  height: 108vh;
  background-color: #212832;
  margin: 0; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  padding: 20px; 
}
.auth-card {
  height: auto;
  background-color: #19202a;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 100%;
  width: 500px;
  margin: 60px auto;
  border-radius: 10px;
}
.auth-card h3 {
  color: var(--color-e7cc67);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: start;
}
.form-cont {
  
  background-color: var(--color-f5f5f7);
 
  border: 1px solid var(--color-494949);
  outline: none;
  width: 100%;
  padding: 10px 10px;
}

.formms-log {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.marg {
  margin-bottom: 26px;
  color: white;
}
.signup {
  background-color: var(--color-e7cc67);
  color: #1f1f1f;
}
.cond-sign {
  margin-top: 20px;
  text-align: center;
  color: white;
  font-size: 13px;
}
.term-text {
  text-decoration: underline;
  color: var(--color-e7cc67);
  margin-top: 5px;
}
.reset-p {
  text-align: center;
  font-size: 13px;
  margin-bottom: 20px;
  color: var(--color-dddddd);
}
.butt-sign {
  background-color: var(--color-e7cc67);
  outline: none;
  padding: 10px 30px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  transition: 0.3s ease;
  color: var(--color-282828);
}
.butt-sign:hover  {
  background-color: transparent;
  color: var(--color-f5f5f7);
  cursor: pointer;
  border: 1px solid var(--color-f5f5f7);
}
.signup-bb {
  background-color: var(--color-f5f5f7);
  outline: none;
  padding: 10px 30px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  transition: 0.3s ease;
}
.signup-bb:hover {
  background-color: transparent;
  color: var(--color-f5f5f7);
  outline: none;
  padding: 10px 30px;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid var(--color-e7cc67);
  transition: 0.3s ease;
}
/*login ends here*/

/*Policy*/
.policy-wrapper {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
}
.Policy {
  background-color: white;
  padding: 30px;
}



/*Policy ends*/


/*single product*/
.form-controlll {
  width: 100%;
  border: 1px solid var(--color-f1eae4);
  padding: 10px;
  outline: var(--color-e7cc67);
}
.wiish-icon {
  display: none;
  
}
.back-icon {
  display: none;
  
}

.blog {
  width: 100% !important;  /* Ensure the carousel container takes full width */
  min-width: 100px;
}







.sizes-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.size-item {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc; /* Default border color */
  margin: 5px 0;
  transition: border 0.3s, background-color 0.3s; /* Smooth transition for background color */
}


.sizes-container .size-item.selected {
  background-color: black!important; /* Background color when selected */
  color: white!important; /* Text color when selected */
}

.sizes-container .size-item:hover {
  background-color: var(--color-e7cc67); /* Change hover color if you want a different shade on hover */
}





.mbt {
  margin-bottom: 5px;
}
.font-iccc {
  font-size: 11px;
}
.upload {
  margin-top: 10px;
  border: 1px solid var(--color-dddddd);
  padding: 10px 10px;
  margin-bottom: 10px;
}
.pos-relative {
  display: flex;
  padding-left: 10px;
  gap: 5px;
  position: relative;
  border: 1px solid var(--color-f1eae4);
}
.c-gre {
  color: var(--color-dddddd);
}
.c-r {
  color: var(--color-dc4814);
}
.btn-close {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 2px;
  background-color: transparent;
  outline: none;
  border: 1px solid #bcbcbc;
  cursor: pointer;
  
}
.main-pic {
  width: 50%;
  min-width: 630px;
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  text-align: center;
}
.main-pic img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.main-pic .js-image-zoom__zoomed-image{
  left: 150px !important;
  padding: 10px;
  border-radius: 10px;
  transform: scale(2);
  z-index: 1000;
}
.main-pic>div {
  border: 1px solid #dedede;
}


.rown {
  display: flex;
  text-align: start;
  align-items: flex-start; /* Aligns items at the start */
  gap: 2px; /* Ensure no gap */
}
.col-6 {
  margin: 0;
  padding: 0;
  flex: 0 0 50%; /* Ensures .col-6 takes up 50% of its parent container */
  max-width: 48%;  /* Also limit max-width to 50% */

}
.main-product-details {
  padding: 20px;
  background-color: white;
  width: 50%;
  min-width: 530px;
  border-radius: 10px;
  margin-left: 50px;
}
.border-bottom {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
}
.number-pro {
  outline: none;
}
.font-13 {
  font-size: 13px;
}
.font-s {
  font-size: 22px;
}
.font-tt {
  font-size: 16px;
}
.para-14 {
  font-size: 14px;
}
.sizes {
  width: 180px;
  padding: 8px 5px;
  font-family: "Rubik", sans-serif;
}
.choose-l-t {
  font-size: 14px;
  font-weight: 500;
}
.choose-but {
  cursor: pointer;
  margin-bottom: 2px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 8px;
}


.addcart-but {
  background-color: var(--color-e7cc67);
  border: none;
  padding: 10px;
  width: 100%;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s ease;
}
.ms-0 {
  background-color: var(--color-282828);
  border: none;
  padding: 10px;
  width: 100%;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s ease;
  color: white;
}
.ms-0:hover {
  background-color: var(--color-e7cc67);
  color: var(--color-282828);
}
.addcart-but:hover {
  background-color: #d7b94c;
}
.icon-link {
  color: var(--color-282828);
  font-size: 14px;
}
.fs-5 {
  font-size: 25px;
  margin-bottom: 0;
  
}
.description-wrapper h4 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.description-wrapper .dees {
  font-size: 14px;
  color: #535353;
}
.reviews-wrapper h4 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.col-rev {
  padding: 30px;
}
.review-head {
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
}
.review-head h4 {
  font-size: 18px;
  font-weight: 500;
}
.review-head p {
  font-size: 14px;
}
.write-r {
  text-decoration: underline;
  color: #282828;
}
.review-form {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
}
.review-form h4 {
  font-size: 16px;
  font-weight: 500;
}
.review p {
  font-size: 14px;
  color: #535353;
  padding-bottom: 20px;
}
.review {
  margin-top: 20px;
}
.rev-t {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.review:not(:last-child) {
  border-bottom: 1px solid #dedede;
}

/*single product ends*/


/*Cart page*/
.cart-wrapper-mobile {
  display: none;
}
.cart-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}
.cart-header h3 {
  font-size: 13px;
  color: #777777;
}
.cart-data {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.cart-col-1 {
  width: 40%;
}
.cart-col-2 {
  width: 10%;
}
.cart-col-3 {
  width: 15%;
}
.cart-col-4 {
  width: 15%;
}
.cart-dis {
  width: 20%;
  background-color: white;
}
.cart-des {
  width: 80%;
}

.cart-col-1 .cart-des p {
  color: #777777;
  font-size: 14px;
}
.fs-4 {
  font-size: 20px;
  margin-bottom: 0;
  cursor: pointer;
}
.cart-form {
  padding: 10px;
}
.text-danger {
  color: var(--color-dc4814);
  cursor: pointer;
}
.check-size {
  font-size: 16px;
}

.empty-cart {
  background-color: white;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.fs-25 {
  font-size: 60px;
  color: var(--color-e7cc67);
  
}
.empty-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-15131f);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  font-size: 13px;
}
.empty-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
.shop-but {
  color: black;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  padding: 10px 50px;
  outline: none;
  background-color: var(--color-e7cc67);
  transition: 0.3s ease;
}
.shop-but:hover {
  color: white;
  background-color: var(--color-15131f);
}
.pad-cart {
  margin-bottom: 80px;
}

/*Cart page ends*/
.cont-map {
  width: 100%;
  position: relative;
}
.contact-size {
  width: 100%;
}
.sub-conta {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: auto;
}
.logo-cont {
  width: 200px;
}

/*Checkout Page*/
.check-details {
  width: 60%;
  margin-left: 10px;
}
.checkout-wrapper {
  padding-left: 120px;
  padding-right: 120px;
  background-color: var(--color-f5f5f7);
  min-height: calc(100vh - 40px);
}
.checkout-left-data .breadcrumb-item {
  list-style: none;
}
.price-fin {
  font-size: 18px;
}
.f-check {
  font-size: 14px;
  color: var(--color-282828);
  font-weight: 500;
}

.form-inputt {
  width: 100%;
}
.form-inputt2 {
  width: 50%;
}
.new-s {
  width: 100%;
  padding: 10px;
  font-family: "Rubik", sans-serif;
}
.mb-20 {
  margin-bottom: 20px;
}
.title-info {
  font-size: 18px;
  font-weight: 500;
}
.checkout-left-data {
  width: 50%;
 
}
.icon-deli {
  border: 1px solid #777777;
  padding: 5px;
 
}
.me-2 {
  color: #282828;
  margin-bottom: 0;
}
.red-button {
  color: white;
  background-color: #d3141a;
}
.checkout-left-data {
  padding: 20px;
  min-width: 600px;
}
.font-sub {
  font-size: 17px;
  font-weight: 600;
}
.checkout-right-data {
  min-width: 600px;
  padding: 20px;
  border-left: 1px solid #eaeaea;
}
.check-image {
  position: relative;
  width: 100px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid #eaeaea;
}
.span-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  position: absolute;
  background-color: #1f1f1f;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  right: -4px;
  top: -4px;
}
.grey-font {
  font-size: 16px;
  font-weight: 500;
  color: #535353;
}
.check-price {
  display: flex;
  justify-content: end;
  width: 150px;
}

.loc-inp {
  display: flex;
 
  gap: 10px;
  margin-bottom: 20px;
  

}
.loc-inpp {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  

}
.buttontt {
  margin-top: 10px;
  width: 100%;
  background-color: var(--color-15131f);
  padding: 15px;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.3s ease;
}
.buttontt:hover {
  background-color: white;
  color: var(--color-15131f);
  border: 1px solid var(--color-15131f);
}
.form-inputt22 {
  cursor: pointer;
  background-color: white;
  padding: 10px;
  display: flex;
  gap: 100px;
  width: 100%;
  border: 1px solid var(--color-f1eae4);
}
.active {
  color: #535353;
}
.dark-font {
  color: black;
}
/*Checkout Page ends*/


.display-none {
  display: none;
}

.navbar-mobile {
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: #15131f;
  padding: 5px 0;
  z-index: 1000;
  
}

.navbar-mobi {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;
  padding: 0;
  
}




.bb {
  display: none!important;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: var(--color-15131f);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  
}
.cart-bar1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart-bar1 p {
  color: var(--color-fbf7f7);
}
.cart-bar {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--color-e7cc67);
  border-radius: 5px;
}
.b-icon {
  border: 1px solid var(--color-e7cc67);
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-bar {
  justify-content: center;
  height: 48px;
  width: 200px;
  border-radius: 5px;
}
.fs-cart1 {
  font-size: 30px;
}
.fs-cart2 {
  font-size: 25px;
  color: var(--color-fbf7f7);
}
.fs-cart3 {
  font-size: 25px;
  color: var(--color-fbf7f7);

}
.cart-bar p {
  font-size: 16px;
}

/*Payment starts*/
.maa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the container takes up the full width */
}
.payback {
  background-color:white;
  padding: 10px 20px 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.mobilem-icon {
  width: 100px;
}
.phone-input {
  background-color: white;
  padding: 10px 0px 10px 0px;
  width: 100%;
}
.enterpho {
  border-bottom: 1px solid var(--color-f1eae4);
  margin-bottom: 40px;
  padding-bottom: 10PX;
}
.phone-ali {
  display: flex; 
  gap: 20px;
}
.phonin {
  flex: 1;
}
.phone-ali .input-field {
  font-size: 18px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  border: none; /* Remove all borders */
  border-bottom: 1px solid var(--color-f1eae4); /* Keep only the bottom border */
  outline: none; /* Remove the outline */
  width: 100%; /* Ensure the input takes the full width of its container */
}
.phone-ali p {
  font-size: 18px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 500;
  color: var(--color-494949);
  width: 100px;
  border-bottom: 1px solid var(--color-f1eae4);
}
.payment-gateway {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 325px;
  padding-right: 325px;
  background-color: var(--color-f5f5f7);
}
.cartde {
  position: relative;
  padding: 10px 40px;
  background-color: white;
  margin-bottom: 10px;
}
.see-d {
  position: absolute;
  right: 10px;
  top: 5px;
  
}
.see-ll {
  color: var(--color-15131f);
  padding: 5px 10px;
  font-size: 14px;
  transition: 0.3s ease;
}
.see-ll:hover {
  background-color: var(--color-15131f);
  color: white;
}
.item-detailss {
  margin-top: 10px;
  font-size: 13px;
}
.payam {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: white;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-pay {
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  color: white;
  background-color: var(--color-15131f);
  cursor: pointer;
  outline: none;
  transition: 0.3s ease;
  border: none;
}
.btn-pay:hover {
  background-color: var(--color-e7cc67);
  color: var(--color-15131f);
}
.item-detailss > div {
  border-bottom: 1px solid #f1eae4; /* Add a border at the bottom of each product */
  margin-bottom: 10px; /* Add some space at the bottom of each product */
  padding-bottom: 10px; /* Add some padding at the bottom for better spacing */
}
.pay-button {
  width: 100%;
  padding-bottom: 10px;
}
#paypal-button-container {
  position: relative;
  z-index: 1; /* Adjust the z-index to ensure it stays below the header */
}
/*Payment ends*/


/*ipad pro*/
@media (min-width: 1024px) and (max-width: 1440px)  {
  .col-6 {
    width: 100%!important;
    min-width: 950px!important;


  }
  .blog .col-8 {
    
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    min-width: 950px!important;
    
  }
  .descrip-photo{
    width: 80%!important;
  }
}

/*iphone*/
@media (min-width: 390px) and (max-width: 431px)  {
  .descrip-photo{
    width: 67%!important;
  }
  .col-6 {
    width: 100%!important;
    min-width: 400px!important;


  }
  .blog .col-8 {
    
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    min-width: 400px!important;
    
  }
}
/*ipad-mini*/
@media (min-width: 768px) and (max-width: 1024px)  {
   
 
  #addon2{
    display: flex;
    align-items: center;
    background-color: var(--color-e7cc67);
    font-size: 14px;
    height: 40px;
    padding: 5px 15px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .bb {
    display: block!important;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: var(--color-15131f);
    display: flex!important;
    justify-content: space-between;
    padding: 10px 20px;
    
  }
  .navbar-mobile {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    

    
  }
 
  .fs-nav {
    padding: 10px;
    height: 60px;
    width: 60px;
    background-color: transparent;
    border-radius: 30px;
    display: flex;
    justify-content: center!important;
    color: var(--color-dddddd);
    font-size: 35px;
   
    
  }
  .navbar-mobi.active .fs-nav {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav2 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav3 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav4 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav5 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .fs-nav2 {
    padding: 10px;
    height: 60px;
    width: 60px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 15px;
  
  }
  .fs-nav3 {
    padding: 8px;
    height: 60px;
    width: 60px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 35px;
  
  }
  .fs-nav4 {
    padding: 5px;
    height: 60px;
    width: 60px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 35px;
  
  }
  .fs-nav5 {
    padding: 12px;
    height: 60px;
    width: 60px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 15px;  
  }
  
  /*Profile Starts*/
  .wel-back {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  .sec-11 {
    color: var(--color-e7cc67);
    background-color: #15131f;
    padding-left: 80px;
    padding-right: 80px;
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: calc(100vh - 40px); /* Subtracting margin-top and margin-bottom */
  box-sizing: border-box; /* Ensures padding doesn't add to the height */
  }



  /*Profile ends*/

   /*Payment starts*/
   .payment-gateway {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    
  }
  .payback {
    padding: 10px 20px 10px 20px;
  
  }
  .cartde {
    position: relative;
    padding: 10px 20px;
  }
  .payam {
    padding-left: 20px;
  }
  /*Payment ends*/


 


  /*header*/
  .mobile-header {
    display: flex;
    padding: 20px;
    align-items: center;
    background-color: #ffffff;
    justify-content: space-between;
    background-color: #0b081f;

  }
  .header-upper .container-xxl .row {
    display: none;
  }
  .search-unit {
    display: block;
    position: sticky;
    top: 0;
    padding: 20px 20px;
    background-color: #0b081f;
    z-index: 999;
  }
  
  .loggo {
    width: 140px;
  }
  .cat-iconn {
    font-size: 25px;
  }
  .display-linkk {
    margin-left: 0px;
    border: none;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .fs-mob {
   
    height: 30px;
    width: 30px;
    background-color: transparent;
    color: var(--color-dddddd);
    
    font-size: 30px;
  }
  .header-top-strip {
    display: none;
    
    
  }
  .header-upper .logo-1 {
    display: none;
  }

  .menu-trigger {
    
    padding: 5px 5px;
   
    margin-right: 10px;
  }

  .display-link {
    
    padding: 5px 10px;
    border: 0.01px solid rgb(108, 108, 108);
    
  }
  .dis-cat {
    display: block;
  }
  .dropdown-menu.active {
    width: 350px;
    opacity: 1;
   
  }

  .cart-ic {
    width: 50px;
  }
.badgee {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-15131f);
  font-size: 15px;
  background-color: var(--color-e7cc67);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: -4px;
}
 

  .cat-icon {
    font-size: 26px;
  }
  

 .w-mb {
  margin-left: -20px;
 }
  .input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 40px;
    width: 100%;
   
  }
  

  #basic-addon2{
    display: flex;
    align-items: center;
    background-color: var(--color-e7cc67);
    height: 40px;
    
  }
 
  .py-3 {
    padding-left: 20px;
    padding-right: 20px;
    
  }

  .header-upper-links .n-mb {
    display: none;
  }
  
  .header-bottom {
    display: none;
    
   
  }
 

  
  /*header ends*/

  /*order page*/
  .order-c {
    margin-left: 50px!important;
    margin-right: 50px!important;
   
    
  }
  .pb-40 {
    padding-bottom: 100px!important;
  }
  .order-sum {
    padding-left: 50px!important;
    padding-right: 50px!important;
  }
  /*order page ends*/


  /*Home*/
  .mm-r {
    display: flex;
    flex-wrap: wrap;
  }
  .home-banner-section {
    margin-top: 0px;
    
  }

  .hero {
    position: relative;
    width: 100%;
    height: 40vh;
    overflow: hidden;
  }
  
  .slider-image {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    object-position: right;
  }
  .main-banner-content {
    top: 50px;
  }
  .main-banner-content h6 span {
    font-size: 30px;
    
}
.main-banner-content h6 {
  font-size: 33px;
}
  .main-banner-content h4 {
    margin-bottom: 0px;
  }
  .main-banner-content h5 {
    font-size: 50px;
    margin-bottom: 10px;
}
 
  .other-banners {
    display: none;
   
  }
  .banner-section-2 {
    display: none;
    
  }

  .serv img{
    margin-right: 10px;
  }

  .strip-des {
    margin-top: -10px;
  }
  .banner-section-3 {
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    
  }
  .cat-design {
    display: none;
  }

  .cat-img {
    width: 50px;
  }
  .cat {
    padding: 10px;
  }
  .col-4 {
    width: 100%;
    min-width: 180px;
    border-radius: 3px;
   
  }
  .other-bann {
    display: block;
    
  }
  .special-wrapper {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 10px!important;
    padding-right: 10px;
  }
  .s-p {
    display: flex;
    justify-content: space-between;
}
.view-p {
  width: 100%;
  text-align: center;
  border-radius: 3px;
  margin-top: 15px;
  
}
  
  .special-product-card {
    
    width: 100%;
    min-width: 250px;
    
    
    
  }
  .descrip-section {
    display: none;
  }
  
  .our-none {
    display: none;
  }

  .blog-wrapper {

    padding-left: 20px;
    padding-right: 20px;
  
  }
  .blog-card {
    
    width: 95%;
    
   
  }

  .image-c:hover {
    transform: scale(1.01);
  }
  .blog-content {
    padding: 15px;
  }
  .button {
    color: var(--color-dddddd);
    font-size: 14px;
    padding: 15px 20px;
    background-color: var(--color-282828);
    border-radius: 5px;
    transition: 0.3s ease;
  }

  .our-pop {
    display: block;
  }
  .dis-no {
    display: block;
  }
  .descrip-section {
    padding-left: 10px;
    padding-right: 10px;
    
  }
  
  .des-cont {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
 
 
  .descrip-photo {
    width: 120%;
    
  }

  .des-doc h6 {
    font-size: 13px;
    font-weight: 200;
  }

  .des-doc h4 {
    margin-top: -6px;
    font-size: 16px;
    
  }
  .des-doc p {
    margin-top: -8px;
    font-size: 13px;
    
  }
  .position-absolute {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  .dis-post {
    display: block!important;
  }
  .big-post {
    display: none;
  }
  .sub-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.sub-banner {
  width: 100%;
  min-width: 300px;
  
}
.sub-details {
  width: 40%;
  top: 40px;

}
.sub-details h1 {
  font-size: 50px;
 
}
.quali-s {
  font-size: 50px;
 
}
.sub-details p {
  font-size: 16px!important;
  padding-right: 10px;
  text-align: start;
  font-family: "Rajdhani", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.m-120 {
  margin-bottom: 120px;
}
.print-sty {
  color: white;
  position: absolute;
  top: 50px;
  right: 10px;
  padding: 80px 120px;
}
.print-sty h2 {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-494949);
}

  /*Home ends*/

  /*Footer starts*/
  footer {
    display: none;
    padding-left: 80px;
    padding-right: 80px;
    
  }
  .container-sign .row {
    display: flex;
    flex-direction: column;
  }
  .container-sign .col-5 {
    margin-bottom: 10px;
  }
  .footer-info .col-2 {
    margin-top: 30px;
  }
  /*Footer ends*/


  /*our store starts*/
  .store-wrapper {
    padding-left: 10px;
    padding-right: 10px;
   
  }
  .filter-card {
    max-width: 180px;
   
  }
  .gr-3 {
    flex: 1;
    border-radius: 3px;
    margin-bottom: 2px;
    min-width: 180px;
    padding: 3px;
    
  }

  .gr-4 {
    flex: 1;
    border-radius: 3px;
    min-width: 250px;
    padding: 3px;
    margin-bottom: 2px;


  }
  .gr-6 {
    width: 26%;
    min-width: 200px;
 
  
  }
  
  
  /*our store ends*/

 

  /*Print starts*/
  .print-wrapper {
    padding-left: 20px!important;
    padding-right: 20px!important;
   
}




  /*Print ends*/



  /*Contact us starts*/
  .col-25 {
    padding-left: 40px!important;
    padding-right: 40px!important;
  }
  .cont-map {
    width: 100%;
    position: relative;
  }
  .contact-size {
    width: 100%;
  }
  .sub-conta {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .logo-cont {
    width: 150px;
  }


  /*Contact us ends*/

/*policy starts*/
  .policy-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
  .Policy {
    padding: 40px;
  }

  /*policy ends*/

  /*Single Product starts*/
  .fs-back {
    font-size: 30px;
  }
  .wish-iccc {
    font-size: 25px;
  }
  .wiish-icon {
    display: block;
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .back-icon {
    display: block;
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 10;  /* Ensure it's on top */
  }
  .dd-none {
    display: none;
  }
.mbbb {
  margin-bottom: 80px;
}

  .section-11 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sing-none {
    display: none;
  }
  .mb-row {
    display: flex;
    flex-direction: column;
  }

  
 
 
  .main-product-details {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: white;
    width: 100%;
    min-width: 700px;
    border-radius: 10px;
    margin-left: 0px;
  }
  .col-6 {
    width: 100%;
  }
  

  .blog {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;  /* Change from wrap to nowrap */
    gap: 10px;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .phon {
    width: 100%;
    min-width: 700px;
  }
  
  /* Ensure .col-4 has consistent widths for different breakpoints */
  .blog .col-8 {
    
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    min-width: 685px;
    
  }
  .blog .react-multi-carousel-item {
    width: auto !important; /* Remove the inline width */
    flex: 1 1 auto !important;
  }
  
  
  /* Make sure the .col-8 is responsive and has proper width constraints */
  .col-8 {
    
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px #0000001a;
    cursor: pointer;
    display: block;
    width: 100%;  /* Ensure it fills the width of the parent .react-multi-carousel-item */
    box-sizing: border-box;
  }
  
  .col-8 img {
    width: 100%;  /* Ensure image scales properly */
    height: auto;  /* Maintain aspect ratio */
  }
  
  /* Optional: Hover effect for product card */
  .col-8:hover {
    border: 0.01px solid #c4c4c4;
  }



  /*Single Product ends*/

  /*Cart starts*/
  .cart-wrapper {
    display: none;
  }
  .cart-wrapper-mobile {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
.cart-datta {
  border: 1px solid var(--color-f5f5f7);
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #4a4a4a1a;
  display: flex;
  align-items: center;

}
.dele-cart {
  font-size: 18px;
  
}
.butt-ddele {
  cursor: pointer;
}
.quantity-btn {
  width: 60px;
  height: 40px;
  font-size: 20px;
}
.cart-dis {
  width: 40%;
  background-color: white;
}
.col-cart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}
.price-stylee {
  font-size: 20px;
}


  .pad-cart {
    padding: 10px;
    margin-bottom: 80px;
  }

  /*Cart ends*/

  /*Checkuot page starts*/
  .checkout-right-data {
    border: none;
  }
  .marg-check {
    border-top: 1px solid #e4e4e4;
    margin-top: 20px;
    padding-top: 10px;
  }
  .none-chech {
    display: none;
  }
  .check-none {
    display: block;
  }
  .check-mob {
    display: none;
  }
  .check-dis {
    display: flex;
    flex-direction: column;
  }
  .checkout-wrapper {
    margin-top: -30PX;
    padding-left: 20px;
    padding-right: 20px;

  }
  .checkout-left-data {
    width: 100%;
  }
  .checkout-right-data {
    min-width: 600px;
    padding: 20px;
    border-left: none;
  }
  .button-check {
    
    text-align: center;
    font-size: 13px;
    border-radius: 3px;
    padding: 8px 30px;
    border: none;
    background-color: var(--color-282828);
    color: white;
}



  /*Checkuot page starts*/

  /**/
  .login-wrapper {
    padding: 20px; /* Adjust padding or margins for smaller screens */
  }

  .auth-card {
    max-width: 100%; /* Ensure the card takes full width */
    margin: 20px auto; /* Center the card */
  }

  .col-27 {
    width: 100%; /* Ensure the column takes full width */
    padding: 0 10px; /* Add padding to prevent content from touching edges */
  }

  /*Wishlist starts*/
  .wishlist-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .wishlist-card {
    width: 13%;
    min-width: 180px;
    cursor: pointer;
    
  }
  /*Wishlist ends*/



}
@media (min-width:  320px) and (max-width: 767px) {
  /*phone-s8*/
  /*Payment starts*/
  .payment-gateway {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    
  }
  .payback {
    padding: 10px 20px 10px 20px;
  
  }
  .cartde {
    position: relative;
    padding: 10px 20px;
  }
  .payam {
    padding-left: 20px;
  }
  /*Payment ends*/
   /*order page*/
   .order-c {
    margin-left: 10px!important;
    margin-right: 10px!important;
   
    
  }
  .pb-40 {
    padding-bottom: 80px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  .order-sum {
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  /*order page ends*/
  /*navbar starts*/
  .navbar-mobile {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0!important;
    padding-right: 10px!important;
   

    
  }
  .d-none-mob {
    display: none;
  }
  .mb-40 {
    margin-bottom: 40px;
  }
  .bb {
    display: block!important;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: var(--color-15131f);
    display: flex!important;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    
  }
 
  .fs-nav {
    padding: 0px 10px!important;
    height: 42px;
    width: 42px;
    background-color: transparent;
    border-radius: 30px;
    display: flex;
    justify-content: center!important;
    color: var(--color-dddddd);
    font-size: 15px;
   
    
  }
  .navbar-mobi.active .fs-nav {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav2 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav3 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav4 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .navbar-mobi.active .fs-nav5 {
    background-color: white;
    color: var(--color-15131f); /* You can also change text color when active */
  }
  .fs-nav2 {
    padding: 10px;
    height: 42px;
    width: 42px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 15px;
  
  }
  .fs-nav3 {
    padding: 8px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 35px;
  
  }
  .fs-nav4 {
    padding: 5px;
    height: 45px;
    width: 45px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 35px;
  
  }
  .fs-nav5 {
    padding: 12px;
    height: 45px;
    width: 45px;
    background-color: transparent;
    color: var(--color-dddddd);
    border-radius: 30px;
    font-size: 15px;
   
   
  }
  
  .navbar-mobile li {
    margin: 0;
  }
  
  .navbar-mobile a {
    text-decoration: none;
    color: var(--color-15131f); /* Change to your preferred color */
  }
 
.mbbb {
  margin-bottom: 40px;
}
  /*navbar ends*/

  /*header*/
  .mobile-header {
    display: flex;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    align-items: center;
    background-color: #0b081f;
    justify-content: space-between;
  }
  .search-unit {
    position: sticky;
    top: 0;
    display: block;
    padding: 15px 15px;
    background-color: #0b081f;
    z-index: 999;
  }
  .p-t {
    padding-top: 15px;
  }
  .cat-iconn {
    font-size: 20px;
    
  }
  .loggo {
    width: 100px;
  }
 
  .display-linkk {
    margin-left: 0px;
    border: none;
    padding: 0px;
    display: flex;
    align-items: center;
    
  }
  .fs-mob {
   
    background-color: transparent;
    color: var(--color-dddddd);
    
    font-size: 30px;
  }
  .cart-ic {
    width: 32px;
  }
  .badgee {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-15131f);
    font-size: 10px;
    background-color: var(--color-e7cc67);
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: -2px;
  }

  .header-upper-links .n-mb {
    display: none;
  }
  
  .header-bottom {
    display: none;
    
   
  }
  .header-upper .container-xxl .row {
    display: none;
  }
  .header-top-strip {
    display: none;
    
    
  }
  .header-upper .logo-1 {
    display: none;
  }
  .header-upper {
    padding: 0 10px!important;
  }

  .header-upper .container-xxl .row {
    padding: 4px 0px;
  }
  .cat-icon {
    font-size: 14px;
  }
 
  .menu-trigger { 
    padding: 2px 2px;
    margin-right: 8px;
  }

  .display-link {
    border: none!important;
    box-shadow: none!important;
    padding: 5px 10px;
    border: 0.01px solid rgb(108, 108, 108);
    
  }
  .dis-cat {
    display: block;
  }
  .dropdown-menu.active {
    width: 200px;
    opacity: 1;
   
  }

  
  .display-link p, .badge {
    margin-left: -5px;
}
  .mob-badge {
    margin-left: -15px;
    margin-top: -10px;
    font-size: 14px;
    border-radius: 100%;
    color: white;
    padding: 2px 5px;
    background-color: #1f1f1f7b;
    border: 0.2px solid #e39f00;
    
  }



  .cat-icon {
    font-size: 26px;
  }
  
  



 .w-mb {
  margin-left: -10px;
 }
  .input-group {
    width: 100%;
    min-width: 240px!important;
    height: 18px;
    background-color: transparent;
    padding-bottom: 15px;
   
  }
 

  #basic-addon2{  
    height: 35px;
    
  }
  /*Home*/
  .not-mob {
    display: none;
  }
  .print-mob {
    display: block;
    
  }
  .ban {
    display: block;
  }
  .not-large {
    display: block;
  }
  .print-mob {
    display: none;
   
  }
  .home-u {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .ban {
    background-color: #15131f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-fbf7f7);
    text-align: center;
    padding: 30px;
    margin: 0;
  }
 
 
  .main-banner-cont h4 {
    font-weight: 300;
    color: var(--color-e7cc67);
  }
  .main-banner-cont h5 {
    font-size: 40px;
  }
  .main-banner-cont h6 span {
    font-size: 20px;
    font-weight: 300;
    background-color: var(--color-e7cc67);
    padding: 2px 8px;
    color: var(--color-15131f);
    border-radius: 50%;
  }
  .main-banner-cont h6 {
    font-size: 28px;
    font-weight: 300;
    margin-bottom: 10px;
  }
  .main-banner-cont .printty {
    margin-top: 10px;
    border: 1px solid var(--color-e7cc67);
    padding: 15px 30px;
    color: var(--color-e7cc67);
    border-radius: 5px;
  }

  .mm-r {
    display: block;

  }
  .home-banner-section {
    margin-top: 0px;
    
  }
  .other-bann {
    display: block;
    
  }
  
  .hero {
    position: relative;
    width: 100%;
    height: 42vh;
    overflow: hidden;
  }
  
  .slider-image {
    width: 100%;
    height: 42vh;
    object-fit: cover;
    object-position: right;
  }
  .main-banner-content {
    top: 15px;
  }

  .main-banner-content h4 {
    margin-bottom: 0px;
  }
  .main-banner-content h5 {
    font-size: 50px;
    margin-bottom: 10px;
}

  .not-large {
    display: block;
  }

  .main-banner-content {
    top: 50px;
    left: 20px;
    position: absolute;
  }
  .main-banner-content h5 {
    font-size: 30px;
  }
  .main-banner-content h6 span {
    padding: 2px 7px;
    font-size: 18px;
    
}
.main-banner-content h6 {
  font-size: 20px;
  font-weight: 100;
}

.main-banner-content p {
  color: var(--color-fbf7f7);
  font-size: 24px;
  font-weight: 100;
  line-height: 20px;
}
.main-banner-content .print {
  margin-top: 10px;
}

  .main-banner-content h4 {
    font-size: 12px;
    font-weight: 300;
  }

  .main-banner-content p {
    font-size: 12px;
  }
  .print {
    font-size: 12px!important;
    padding: 10px!important;
  }
  .strip-des {
    margin-top: -18px!important;
    
  }
.section-heading {
  font-size: 14px;
  margin-bottom: 5px;
  padding-bottom: 3px;
  padding-left: 10px;
}

.banner-section-3 {
  background-color: white;
  padding-bottom: 5px!important;
  padding-top: 0px!important;
}

.product-details {
  text-align: center;
}
.blog-carousel {
  display: flex;
  justify-content: space-evenly;  /* Evenly distribute space between items */
  flex-wrap: wrap;
  gap: 10px; 
  width: 100%;
}
.banner-section-4 {
  padding-left: 0px!important;
  padding-right: 0px!important;
}
/* Update for responsive behavior */
.blog-carousel .col-4 {
  flex: 1 1 30%;  /* Ensure each product item takes up 30% of the width */
  min-width: 160px;  /* Prevent items from getting too small */
  margin-bottom: 10px!important;  /* Add margin between items */
  padding: 2px;
}
.react-multi-carousel-dot-list {
  display: none !important;  /* Hide the dots navigation */
}
/* Ensure product items are displayed correctly */
.blog-carousel .product-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  box-shadow: 0 0 10px #00000008;
  border: 1px solid #f9f9f9;
  width: 100%;  /* Ensure it fills the width of the parent */
}

.blog-carousel .react-multi-carousel-item {
  transition: transform 0.5s ease-in-out;  /* Smooth slide transition */
}

.react-multi-carousel-item {
  margin: 2px;  /* Optional: Adds spacing between items */
}

/* For specific product item width adjustments */
.blog-carousel .product-item:nth-child(1),
.blog-carousel .product-item:nth-child(2) {
  width: 45% !important;  /* Make first and second items 45% */
}

.blog-carousel .product-item:nth-child(3) {
  width: 10% !important;  /* Make third item 10% */
}

/* Ensure react-multi-carousel items behave correctly */
.blog-carousel .react-multi-carousel-item {
  width: auto !important;  /* Ensure width is not set to 113px */
  flex: 0 1 auto !important;  /* Allow flex items to grow or shrink naturally */
}
.blog-carousel .col-4:last-child {
  margin-right: 0;  /* Prevent extra margin on the last item */
}
/* Optional: Adjust the appearance of carousel dots */
.react-multiple-carousel-dot button {
  width: 8px !important;
  height: 8px !important;
  opacity: 1;
  border-width: 1px !important;
  margin-right: 3px !important;
}

.react-multiple-carousel__arrow--left {
  display: none;
}
.react-multiple-carousel__arrow--right {
  display: none;
}

.react-multi-carousel-dot button {
  width: 8px!important;
  height: 8px!important;
  opacity: 1;
  border-width: 1px!important;
  margin-right: 3px!important;
  
}

.col-4 h6 {
  font-weight: 500 !important;
  font-size: 12px!important;
}
.product-details .font-star {
  font-size: 13px !important;
}
.price-style {
  margin-top: 2px!important;
  color: var(--color-282828);
}






.sub-banner {
  width: 100%;
  min-width: 300px;
  position: relative;
}
.sub-details {
  width: 42%;
  top: 10px;

}
.sub-details p {
  font-size: 14px;
}

.sub-details h1 {
  font-size: 25px;
 
}
.quali-s {
  font-size: 25px;
 
}


.bord-sub {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.cat-image-d {
  height: 80px!important;
  width: 80px!important;
  font-size: 14px!important;
}
.category {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.category p {
  font-size: 13px;
}




.other-bann {
  display: block;
  
}
  .other-banners {
    display: none;
   
  }
  .banner-section-2 {
    display: none;
    
  }

  .top-banners {
    gap: 2px!important;
  }
  .lower-main {
    margin-top: -2px!important;
  }

  .serv img{
    margin-right: 10px;
  }

  .strip-des {
    margin-top: -10px;
  }
  .banner-section-3 {
    padding-bottom: 0px!important;
    padding-top: 20px;
    padding-left: 1px;
    padding-right: 1px;
    
  }
  .mbb-n {
    margin-bottom: 20px;
  }
  .cat-design {
    display: none;
  }

  .cat-img {
    width: 50px;
  }
  .cat {
    padding: 10px;
  }
  .col-4 {
    flex: 1;
    width: 100%;
    margin-bottom: 2px;
  }
  .special-wrapper {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 1px!important;
    padding-right: 1px;
  }
  .special-product-card {
     width: 100%;
     padding: 0px;
  }
  .s-p {
    padding: 10px!important;
    display: flex;
    justify-content: space-between;
   
    padding: 10px;
  }
  .special-product-card .price-style {
    font-size: 11px;
  }
  .special-product-card .price-style strong {
    font-size: 15px;
  }
  .special-product-content h5 {
    margin-top: 5px;
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 11px;
    
}
.des-special {
  font-size: 12px;
  font-weight: 500;
}
.discount-till {
  margin-top: 10px;
}
.discount-till p {
  font-size: 12px;
}
.discount-till .badge-2 {
  font-size: 13px;
  padding: 5px 10px;
  margin: 1px;
  border-radius: 50%;
}
.view-p {
  width: 100%;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  margin-top: 10px;
  
}
.s-p-img {
  width: 100%;
  
}
.s-p-s-img {
  max-width: 35px ;
 
}
.prod-count p {
  margin-bottom: 8px;
  font-size: 13px;
}

.pro-img {
  margin-right: 5px;
  width: 50%;
}

.pro-small-img {
  gap: 1px;
  width: 50%;
}

  .descrip-section {
    display: none;
  }
  
  .our-none {
    display: none;
  }

  .blog-wrapper {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  
  }
  .blog-card {
     width: 98%;
      border-radius: 3px;
   
  }
  .price-styleet {
    margin-top: 2px;
    font-size: 4px;
    color: var(--color-972e22);
    
  }
  .price-styleet strong {
    font-size: 12px;
    color: var(--color-972e22);
  }

.mb-5 {
  margin-bottom: 3px;
}
  .image-c:hover {
    transform: scale(1.01);
  }
  .image-c {
    border-radius: 2px;
  }
  .blog-content {
    padding: 8px;
    border-radius: 3px;
  }
  .blog-content .date {
    font-size: 11px;
    color: #808080;
    margin-bottom: 5px;
}
.blog-content .title {
  font-size: 13px;
  margin-bottom: 8px;
}
.blog-content p {
  font-size: 13px;
  margin-bottom: 10px;
}
  .button {
    width: 100%;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
    padding: 8px 20px;
    
  }

  .our-pop {
    display: block;
  }
  .dis-no {
    display: block;
  }
  .descrip-section {
    padding-left: 10px;
    padding-right: 10px;
    
  }
  
  .des-cont {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

 
  .descrip-photo {
    width: 55%;

    
  }

  .des-doc h6 {
    font-size: 11px;
    font-weight: 200;
    color: var(--color-dddddd);
  }

  .des-doc h4 {
    margin-top: -10px;
    font-size: 14px;
    
  }
  .des-doc p {
    margin-top: -8px;
    font-size: 11px;
    
  }
  .position-absolute {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .dis-post {
    display: block!important;
  }
  .big-post {
    display: none;
  }

  /*Home ends*/
  /*navbar*/

  
  .navbar-mobi {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2px;
    
  }
  .fs-nav {
    font-size: 18px;
    margin-bottom: 0;
    margin-left: 10px;
  }
  .fs-nav2 {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 10px;
  }
  .fs-nav3 {
    font-size: 18px;
    margin-bottom: 0;
    margin-left: 15px;
  }
  .fs-nav4 {
    font-size: 20px;
    margin-bottom: 0;
    margin-left: 15px;
  }
  .fs-nav5 {
    font-size: 18px;
    margin-bottom: 0;
    margin-left: 5px;
  }
  
  .navbar-mobile li {
    margin: 0;
  }
  
  .navbar-mobile a {
    font-size: 13px;
    text-decoration: none;
    color: #333; /* Change to your preferred color */
  }
 

.center-t {
  font-size: 13px;
}

.m-120 {
  margin-bottom: 110px;
}

/*Footer Starts*/
footer .row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
footer {
  display: none;
  padding-left: 20px;
  padding-right: 20px;
  
}

.form-foot{
  background-color: white;
  height: 28px;
  flex: 1;
  
}
.form-foot::placeholder {
  font-size: 12px;
  padding: 5px!important;
}
footer .row {
  padding-top: 10px;
  padding-bottom: 20px;
}
.sign-text {
  font-size: 16px!important;
}

#addon2 {
  background-color: var(--color-e7cc67);
  height: 28.5px;
  padding: 5px 8px;
  font-size: 13px;
}
.col-7 .input-group {
  width: 100%;
  min-width: 280px;
  height: 18px;
  background-color: transparent; 
 
}

.footer-info {
  padding-top: 20px;
  padding-bottom: 40px;
}
.footer-info .info-row {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.address {
  font-size: 13px;
  padding-top: 5px;
}
.mobile, .email {
  padding-top: 8px;
  font-size: 13px;

}
.footer-link-text {
  font-size: 13px;

}
.para-install {
  font-size: 13px;
}
.text-info-footer {
  padding-bottom: 5px;
}


/*Footer Ends*/

/*Wishlist starts*/

.wishlist-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  
}
.wishlist-card .cross {
  top: 3px;
  left: 90px;
  padding: 3px;
  cursor: pointer;
}

/*Wishlist ends*/


/*Policy starts*/
.policy-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Policy {
  padding: 10px;
}
/*policy ends*/


/*Single product starts*/
.fs-back {
  font-size: 20px;
}
.wish-iccc {
  font-size: 15px;
}
.wiish-icon {
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
}
.back-icon {
  display: block;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 10;  /* Ensure it's on top */
}
.dd-none {
  display: none;
}
.mbbb {
  margin-bottom: 70px;
}

.col-6 {
  width: 100%;
}

.blog {
  display: flex;
  justify-content: space-between;  /* Center items to ensure active item is centered */
  flex-wrap: wrap;  /* Prevent wrapping so the carousel slides horizontally */
  gap: 10px;
  width: 100%;
  overflow: hidden;  /* Hide items that are off-screen */
  position: relative;
}
.phon {
  width: 100%;
  min-width: 350px;
}

/* Ensure .col-4 has consistent widths for different breakpoints */
.blog .col-8 {
  
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  min-width: 344px;
  
}
.blog .react-multi-carousel-item {
  width: auto !important; /* Remove the inline width */
  flex: 1 1 auto !important;
}


/* Make sure the .col-8 is responsive and has proper width constraints */
.col-8 {
  
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px #0000001a;
  cursor: pointer;
  display: block;
  width: 100%;  /* Ensure it fills the width of the parent .react-multi-carousel-item */
  box-sizing: border-box;
}

.col-8 img {
  width: 100%;  /* Ensure image scales properly */
  height: auto;  /* Maintain aspect ratio */
}

/* Optional: Hover effect for product card */
.col-8:hover {
  border: 0.01px solid #c4c4c4;
}
.sing-none {
  display: none;
}
.mb-row {
  display: flex;
  flex-direction: column;
}

.description-wrapper h4 {
  font-size: 14px;
}
.section-11 {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.main-product-details {
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-width: 340px;
  border-radius: 10px;
  margin-left: 0px;
}
.font-s {
  margin-top: 8px!important;
  font-size: 14px;
}
.font-s strong {
  font-size: 18px;
}
.font-tt {
  font-size: 14px;
  margin-top: 10px;
}
.sizes {
  width: 50px;
  padding: 5px 5px;
  font-family: "Rubik", sans-serif;
}
.addcart-but {
  padding: 10px;
  font-size: 14px;
  margin-top: 0px;
}
.icon-link {
  gap: 5px;
}
.add-wi {
  font-size: 16px;
}
.descrip-mob {
  
  padding: 10px;

}
.descrip-mob p {

  font-size: 13px!important;

}

.reviews-wrapper h4 {
  font-size: 14px;
}
.col-rev {
  padding: 10px;
}
.write-r {
  gap: 5px;
}
.write-r p {
  font-size: 13px;
}
.mob-wr {
  display: none;
}
.text-b {
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}
.button-cont {
  padding: 5px 15px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s ease;
}
/*Single product ends*/




/*print starts*/
.print-wrapper {
  padding-left: 10px!important;
  padding-right: 10px!important;
 
}
.print-wrapper {
  margin-bottom: 100px!important;
}
.adv-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bar-form {
  padding: 5px;
  
  
}


/*print ends*/


/*Contact us page starts*/
.cont-map {
  width: 100%;
  position: relative;
}
.contact-size {
  width: 100%;
}
.sub-conta {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin: auto;
}
.logo-cont {
  width: 100px;
}
.col-25 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.cont-wrap {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  border-radius: 5px;
  gap: 15px;
 
}

.contact-title {
  font-size: 14px;

}
.form-c {
  min-width: 320px;
  padding: 8px;
}
.cont-wrap .text-b {
  min-width: 320px;
  padding: 8px;
  margin-bottom: 5px;
  
}

.cont-wrap .button-cont {
  padding: 10px 15px;
  font-size: 14px;
  margin-bottom: 15px;
  background-color: var(--color-15131f);
}

/*Contact us page ends*/

/*Cart page starts*/
.cart-wrapper {
  display: none;
}
.cart-wrapper-mobile {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cart-datta {
padding: 3px 3px;
font-size: 13px;
margin-bottom: 10px;
box-shadow: 0 2px 10px #9494941a;
display: flex;
align-items: center;

}
.dele-cart {
font-size: 18px;
}
.fo-cart {
  font-size: 15px;
}
.cart-col-1 {
  width: 30%;
}
.cart-dis {
min-width: 80px;
background-color: white;
}
.col-cart {
display: flex;
flex-direction: column;
gap: 10px;
width: 70%;
}
.price-stylee {
font-size: 15px;
}
.pad-cart {
  margin-top: 30px;
  margin-bottom: 80px;
  padding: 0px;
  align-items: last baseline;
  gap: 10px;
}

/*Cart page ends*/

/*Checkout page starts*/
.checkout-left-data {
  margin-bottom: 20px;
}
.checkout-right-data {
  border: none;
}
.marg-check {
  border-top: 1px solid #e4e4e4;
  margin-top: 20px;
  padding-top: 10px;
}
.none-chech {
  display: none;
}
.check-none {
  
  display: block;
}
.check-mob {
  display: none;
}
.checkout-wrapper {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: calc(100vh - 40px); /* Adjust based on header/footer height */
}
.checkout-left-data {
  padding: 0px;
  min-width: 100%;
}
.loc-inp {
  display: flex;
  flex-direction: column;

}
.form-inputt2 {
  width: 100%;
}
.check-dis {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mr {
  margin-right: 10px;
}
.button-check {
  width: 100%;
  text-align: center;
  font-size: 13px;
  border-radius: 3px;
  padding: 8px 10px;
  border: none;
  background-color: var(--color-282828);
  color: white;
}
.flex-check {
  gap: 5px;
}
.check-image {
  position: relative;
  width: 60px;
  
}
.checkout-right-data {
  min-width: 280px;
  padding: 0px;
  
}
.f-chech {
  font-size: 13px;
}
.new-s {
  width: 100%!important;
  flex: 1!important;
}
.logo-check {
  width: 100px;
}
/*Checkout page ends*/


/*Our store page starts*/
.filter-sort-grid {
  display: none;
}
.store-wrapper .col-21 {
  display: none;
}
.right-dis {
  display: none;
}
.store-wrapper {
 
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 118px;
  padding-right: 118px;
  display: flex;
  gap: 10px;
}
.store-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  
}


.products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}
.store-wrapper {
  background-color: white;
}
.gr-3 {
  flex: 1;
  border-radius: 3px;
  margin-bottom: 2px;
  min-width: 150px;
  width: 50%;
  padding: 3px;
}
.product-details {
  margin-top: -10px!important;
}
.product-details p {
  font-size: 11px;
}
.product-details .brand {
  font-size: 12px;
}
.none {
  display: none;
}

/*Our store page ends*/

/*profile starts*/
.wel-back {
  padding-left: 20px;
  padding-right: 20px;

}
.sec-11 {
  color: var(--color-e7cc67);
  background-color: #15131f;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
  margin-top: 20px;
  min-height: calc(100vh - 40px); /* Subtracting margin-top and margin-bottom */
  box-sizing: border-box;
}


/*profile ends*/

/*sign up*/
.auth-card {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  width: 300px;
  border-radius: 3px;
  
}
/*sign up ends*/



}

@media (max-width: 479px) {
  /* Styles */
}

/*Print Form*/
.print-wrapper {
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 20px;
}
.print-bar {
  display: flex;
  height: 40px;
  margin-bottom: 10px;
}
.bar-fo {
  background-color: var(--color-e7cc67);
  max-width: 200px;
  width: 2%;
}
.bar-form {
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--color-f5f5f7);
  flex: 1;
  background-color: var(--color-15131f);

}
.bar-form p {
  font-weight: 200;
  font-size: 17px;
}
.upper-f {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.adv-form {
  display: flex;
  gap: 10px;
}


.a0-img {
  width: 100%;
  min-width: 300px;
  
}
.pap-size {
  margin-top: 20px;
}
.advert-p {
  background-color: var(--color-15131f);
}
.print-descrip {
 padding-left: 30px; 
 color: var(--color-dddddd);

}
.mr-10 {
  margin-right: 10px;
}
.black-b {
  background-color: var(--color-282828);
  height: 70vh;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 20px;
  margin-bottom:20vh;
}
.button-p {
  margin-top: 40px;
  border: 1px solid var(--color-f5f5f7);
  background-color:transparent;
  padding: 15px;
  color: var(--color-f5f5f7);
  border-radius: 5px;
}
.button-p:hover {
  border: 1px solid var(--color-e7cc67);
  color: var(--color-e7cc67);
}
.sub-t-p {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 10px;
}
.font-w {
  color: white;
}
.print-form {
  position: relative;
  padding-left: 120px;
  padding-right: 120px;
}
.form-p {
  background-color: var(--color-f5f5f7);
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-dddddd);
  margin: 2px;
}
.st-fonts {
  font-size: 45px;
  color: var(--color-f5f5f7);
}
.st-font {
  margin-bottom: 20px;
  font-size: 52px;
  color: var(--color-f5f5f7);
  margin-top: -10px;
  
}
.q-font {
  margin-top: -10px;
  font-size: 62px;
  font-weight: 100;
  color: var(--color-f5f5f7);
}
.form-print-input {
  width: 600px;
  position: absolute;
  top: 60px;
  right: 200px;
  background-color: white;
  border: 1px solid var(--color-dddddd);
  border-radius: 5px;
  padding: 30px;
}
.main-b {
  background-color: var(--color-f5f5f7);
}
.up-but {
  padding: 8px;
  margin-top: 8px;
}
.button-submit {
  cursor: pointer;
  border: none;
  margin-top: 0;
  text-align: center;
  padding: 15px;
  background-color: var(--color-15131f);
  color: white;
  transition: 0.3s ease;
}
.button-submit:hover {
  background-color: var(--color-e7cc67);
  color: var(--color-282828);
}
/*Print Form ends*/





/*order*/
.pad {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  
}
.col-12 {
  display: flex;
  flex-direction: column;
  
}
.col-122 h1 {
  font-weight: 400;
  margin-bottom: 20px;
}
.col-122 p {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 20px;
}
.col-122 {
  color: white;
  background-color: var(--color-15131f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
 
}
.basket {
  color: var(--color-f1eae4);
  font-size: 50px;
  
}
.sty-ba {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #19202a;
  border: 1px solid #2c343e;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  margin-bottom: 20px;
}
.border-order {
  position: relative;
}
.border-order p {
  font-size: 13px;
  color: var(--color-494949);
}
.cart-diss {
  width: 13%;
}
.mb-5 {
  margin-bottom: 5px;
}
.order-details {
  display: flex;
  flex-direction: column;
  
}
.b-bott {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-f1eae4);
}
.d-row {
  display: flex;
  gap: 20px;
}
.order-c {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  margin-right: 200px;
  border: 1px solid var(--color-dddddd);
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 20px;
}
.col-111 {
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 2px;
  background-color: green;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 5%;
  min-width: 100px;
}
.col-00 {
  color: white!important;
}
.ship {
  font-size: 13px;
  color: var(--color-494949);
}
.see-l {
  font-size: 14px;
  color: var(--color-ee702a);
  padding: 5px;
  transition: 0.3s ease;
  margin-bottom: 20px;
}
.see-l:hover {
  background-color: var(--color-e7cc67);
  color:  var(--color-15131f);
}
.see-det {
  position: absolute;
  top: 10px;
  right: 10px;
}
.order-sum {
  padding-left: 200px;
  padding-right: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.order-sum strong {
  color: #030d17;
  font-weight: 400;
}
.order-sum p {
  color: #393a3b;
  font-size: 14px;
}
.row {
  display: flex;
  justify-content: space-between;
  text-align: start;
}
.color {
  background-color: var(--color-e7cc67);
}

.color-des{
  padding: 5px;
  cursor: pointer;
  
}
.color-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--color-e7cc67);
  width: 30px; /* adjust this size to your needs */
  height: 30px; /* adjust this size to your needs */

}
.color-border.selected {
  border-color: var(--color-15131f); /* Change border to red when selected */
}
.col-1 {
  flex: 1; /* Ensures each column occupies equal space */
  text-align: start;
}

.bg-secondary {
  color: white;
  background-color: var(--color-282828);
}
.form-p {
  background-color: white;
}
.form-text {
  font-size: 13px;
}
/*order ends*/


/*Help Page*/
.help-b {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background-color: var(--color-282828);
  color: var(--color-f5f5f7);
}
.help-s {
  font-size: 30px;
}

/*Help Page ends*/

.category {
  min-width: 100px;
  width: 2%;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
  
}
.cat-image-d {
  background-color: white;
  height: 100px;
  width: 100px;
  padding: 15px;
  border-radius: 50%;
  border: 1px solid var(--color-f1eae4);
  margin-bottom: 2px;
  
}
.category:hover .cat-image-d {
  border: 1px solid var(--color-15131f);

}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px)!important;
  z-index: 1;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px)!important;
}



@media (min-width: 2000px) and (max-width: 4000px)  {
  .hero {

    height: 70vh!important;
  
  }
.py-3 {
  padding-left: 320px;
  padding-right: 320px;
}
.main-banner-content {
  left: 16%;
 
}
.checkout-wrapper {
  padding-left: 305px!important;
}
.hero, .slider-image {
  height: 70vh!important;
}
.header-top-strip {
  padding-left: 320px;
  padding-right: 320px;
  
  
}
.banner-section-2 {
  padding-left: 320px;
  padding-right: 320px;
}
.banner-section-3 {
  padding-left: 320px;
  padding-right: 320px;
}
.special-wrapper {
  padding-left: 320px!important;
  padding-right: 320px;
}
.descrip-section {
  padding-left: 320px;
  padding-right: 320px;
}
.blog-wrapper {
  padding-left: 320px;
  padding-right: 320px;
}
footer {
  padding-left: 320px;
  padding-right: 320px;
}
.store-wrapper {
  padding-left: 320px;
  padding-right: 320px;
}
.sec-11, .section-11 {
  padding-left: 320px;
  padding-right: 320px;
}
.policy-wrapper {
  padding-left: 320px;
  padding-right: 320px;
}
.col-25 {
  padding-left: 320px;
  padding-right: 320px;
}
.wel-back {
  padding-left: 320px!important;
  padding-right: 320px!important;
}
.wishlist-wrapper {
  padding-left: 320px!important;
  padding-right: 320px!important;
}
.print-wrapper {
  padding-left: 320px!important;
  padding-right: 320px!important;
}
}

